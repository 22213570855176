.dashboard-container {
	max-width:860px;
}
.dashboard-container .header {
	margin:30px 0 20px 10px;
}
.dashboard-container .blocks {
	display:flex;
	flex-wrap:nowrap;
	justify-content:space-between;
}
.dashboard-container .blocks .block-wrap {
	width:100%;
    flex-basis:33%;
    padding-left:8px;
    margin-bottom:16px;
    padding-right:8px;
}
.dashboard-container .blocks .block-wrap-wide {
	flex-basis:50%;
}
.dashboard-container .blocks .block-wrap-single {
	flex-basis:100%;
}
.dashboard-container .blocks .block-wide-row {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.dashboard-container .blocks .block-wrap .block {
	width:100%;
	height:100%;
	min-height:120px;
	padding:20px;
	background:#fff;
	border-radius:10px;
	box-shadow:0 8px 20px rgb(122 136 150 / 20%);
}
.dashboard-container .blocks .block-wrap .block b {
	font-size:120%;
	font-weight:500;
}
.dashboard-container .blocks .block-wrap .block .chart {
	display:flex;
	flex-wrap:nowrap;
	justify-content:space-between;
	align-items:flex-start;
	margin-top:30px;
}
.dashboard-container .blocks .block-wrap .block .chart .chart-info {
	font-size:130%;
	font-weight:600;
	line-height:1.1;
	text-align:center;
}
.dashboard-container .blocks .block-wrap .block .chart .chart-info span {
	display:block;
	font-size:80%;
	font-weight:400;
}
.dashboard-container .blocks .block-wrap .block .chart .chart-info .chart-info-arrow {
	display:flex;
	align-items:flex-end;
}
.dashboard-container .blocks .block-simple {
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	width:32%;
	height:100%;
	padding:8px 12px;
	background:#f4f6f7;
	border-radius:10px;
}
.dashboard-container .blocks .block-simple .block-simple-value {
	font-size:140%;
	font-weight:600;
}

.dashboard-container .blocks .block-wide .list {
	width:100%;
	margin-top:10px;
}
.dashboard-container .blocks .block-wide .list div.row {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	padding:4px 10px;
}
.dashboard-container .blocks .block-wide .list div.row:nth-child(odd) {
	background:#f4f6f7;
}
.dashboard-container .blocks .block-wide .list div.row div.amount {
	font-weight:600;
}
.dashboard-container .blocks .block-wide .list div.name {
	font-weight:600;
}
.dashboard-container .blocks .block-wide .list div.name a {
	color:#000;
}

@media (max-width: 768px) {
	.dashboard-container {
		width: 90%;
		margin: auto;
	}
}