.login-container {
	position:relative;
	width:600px;
	margin:100px auto 0;
	padding:50px;
	background:#FFF;
	border-radius:5px;
	box-shadow:0 5px 15px rgba(37, 48, 62, .2);
}
.login-container img {
	height:80px;
	margin:0 0 30px;
	display:block;
}
.login-container .form-column {
	display:block;
	margin:20px 0 30px;
}
.login-container .form-column label {
	margin:20px 0;
	position:relative;
}
.login-container .form-column label:hover .input-panel {
	cursor:pointer;
	border-color:#999;
}
.login-container .form-column label span.placeholder {
	margin-left:20px;
	position:absolute;
	left:0;
	top:-11px;
	transition:.2s;
	transition-timing-function:ease;
	transition-timing-function:cubic-bezier(.25, .1, .25, 1);
	opacity:.4;
	color:#000;
	cursor:pointer;
	white-space:nowrap;
}
.login-container .form-column .input-panel {
	padding:30px 50px 10px 20px;
	width:100%;
	border-radius:4px;
	border:1px solid #ccc;
	font-size:110%;
}
.login-container .form-column .input-panel:focus,
.login-container .form-column label:hover .input-panel:focus {
	border:2px solid #21add9;
	padding:29px 49px 9px 19px;
}
.login-container .form-column .input-panel:focus+span.placeholder,
.login-container .form-column .input-panel:not(:placeholder-shown)+span.placeholder {
	opacity:1;
	color:#777;
	transform:translateY(-10px) translateX(0);
	font-size:80%;
}
.login-container .form-column .input-panel:focus+span.placeholder,
.login-container .form-column .input-panel:not(:-ms-input-placeholder)+span.placeholder {
	opacity:1;
	color:#777;
	transform:translateY(-10px) translateX(0);
	font-size:80%;
}
.login-container .form-column .input-panel[iserror=true],
.login-container .form-column .input-panel[iserror=true]:focus,
.login-container .form-column label:hover .input-panel[iserror=true] {
	border-color:#ff402b;
	background-color:#fff9f8;
	background:url(./images/attention.svg) 99% 9px no-repeat;
}
.login-container button.btn {
	border-radius:6px;
    background:#122034;
	color:#fff;
	transition:.3s;
	text-transform:uppercase;
	border:none;
	font-weight:500;
	padding:20px 50px;
	font-size:13px;
}
.login-container button.btn:hover {
	cursor:pointer;
	opacity:.8;
}


@media (max-width: 768px) {
	.login-container {
		width: 90%;
		margin: auto;
	}
}