.form-container {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.form-container form {
	max-width:900px;
}
.form-container .cell {
	margin:0 20px 20px 0;
}
.form-container .cell-block .cell {
	width:215px;
}
.form-container .cell-info {
	padding:10px;
	margin:0 0 10px;
	background:#f4f4f4;
	border-radius:5px;
	flex-wrap:wrap;
}
.form-container .cell-info .cell {
	margin-bottom:10px;
}
.form-container .cell-info .title {
	flex:0 0 100%;
	margin-bottom:10px;
	font-weight:700;
}
.form-container .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.form-container input {
	display:block;
	width:215px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.form-container input.double {
	width:450px;
}
.form-container input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.form-container .cell-oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.form-container .buttons {
	position:fixed;
	bottom:0;
	width:100%;
	padding:20px 10px 30px;
	margin-left:-30px;
	border-top:1px solid #ccc;
	display:flex;
	flex-direction:row;
	align-items:center;
	background:#f4f3ef;
}
.form-container .buttons button {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
	margin-left:5px;
	margin-right:20px;
}
.form-container .buttons button:hover {
	opacity:.8;
	cursor:pointer;
}








@media (max-width: 768px) {
	.form-container .cell {
		margin: 0 0 20px;
	}

	.form-container .cell-oneline .cell:not:first-child{
		margin-left: 4%;
	}

	.form-container input.double {
		width: 100%;
	}
}